import {OpticianDesign} from "../../../gen/api";
import {Form, FormHeadline, FormInput, FormLabel} from "../../../common/Controls";
import {FileUpload} from "../../../common/FileUpload";
import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import Lottie from "react-lottie-player";


export const Waiting = (props: {
  design: OpticianDesign,
  onDesignChange: (design: OpticianDesign) => void
}) => {
  const {design, onDesignChange} = props;

  const [waitingTime, setWaitingTime] = useState(design.waitingTime?.toString() ?? "5");
  const [waitingAnimationFile, setWaitingAnimationFile] = useState<File | null>(null);
  const [waitingAnimationData, setWaitingAnimationData] = useState<{} | null>(null);

  useEffect(() => {
    setWaitingAnimationData(null);
    if (waitingAnimationFile) {
      const stringReader = new FileReader();
      stringReader.onload = () => setWaitingAnimationData(JSON.parse(stringReader.result as string));
      stringReader.readAsText(waitingAnimationFile);
    } else if (design.waitingAnimation) {
      fetch(design.waitingAnimation).then(response => {
        return response.json()
      }).then(text => {
        setWaitingAnimationData(text);
      }).catch(ex => {
        console.error("Failed to load lottie animation", ex);
      })
    }
  }, [design.waitingAnimation, waitingAnimationFile]);

  const updateWaitingTime = (waitingTime: string) => {
    setWaitingTime(waitingTime);
    try {
      const waitingTimeNumber = parseFloat(waitingTime.replaceAll(",", "."))
      onDesignChange({ ...design, waitingTime: waitingTimeNumber })
    } catch (ex) {}
  }

  return (
    <>
      <FormHeadline>Warte-Animation</FormHeadline>
      <Form>
        <FormInput
          label="Wartezeit (in Sekunden)"
          value={waitingTime}
          onChange={updateWaitingTime}
        />
        <FormLabel>Animation</FormLabel>
        <Box sx={{
          marginTop: "24px"
        }}>
          {waitingAnimationData &&
            <Lottie
              play
              animationData={waitingAnimationData}
              style={{width: 150, height: 150}}
            />
          }
          <FileUpload onFileRefChange={waitingAnimation => onDesignChange({...design, waitingAnimationRef: waitingAnimation ?? undefined})} accept=".json" onFileChange={setWaitingAnimationFile} />
        </Box>
      </Form>
    </>
  )
}

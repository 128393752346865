import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApiProvider } from './ApiProvider';
import { AuthenticationRequired } from '../login/AuthenticationRequired';
import { Login } from '../login/Login';
import { Portal } from '../portal/Portal';
import { CustomTheme } from './CustomTheme';



function App() {
  return (
    <CustomTheme>
      <SnackbarProvider autoHideDuration={4000} resumeHideDuration={1500}>
        <ApiProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/">
                <AuthenticationRequired>
                  <Portal />
                </AuthenticationRequired>
              </Route>
            </Switch>
          </BrowserRouter>
        </ApiProvider>
      </SnackbarProvider>
    </CustomTheme>
  );
}

export default App;

import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { styled, SxProps } from "@mui/system";
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@mui/material";
import { useAsync } from "react-async-hook";

export interface DataTableColumnDef<T> {
  label: string | ReactNode;
  name?: (keyof T & string);
  sortable?: boolean;
  sortName?: string;
  render?: (row: T) => ReactNode;
}

interface DataTableColumn<T> {
  label: string | ReactNode;
  name: string;
  sortable: boolean;
  sortName: string;
  render: (row: T) => ReactNode;
}

export interface PaginationRequest {
  page: number;
  size: number;
  orderColumn?: string;
  orderDir?: "asc" | "desc" | any;
  search?: string;
}

interface PaginationResponse<T> {
  recordsTotal?: number;
  data?: T[];
}

const ColumnLabel = styled(Box)(() => ({
  fontWeight: "bold",
  marginLeft: "10px",
  textSize: "12px",
  textTransform: "uppercase"
}));

export type DataSupplier<T> = (request: PaginationRequest) => Promise<PaginationResponse<T>>

export type SortDir = "asc" | "desc";

interface DataTableBodyProps<T> {
  columns: DataTableColumnDef<T>[];
  dataSupplier: DataSupplier<T>;
  sx?: SxProps<Theme>;
  page: number;
  rowsPerPage: number;
  search: string;
  onRowsTotalChange: (rowsTotal: number) => void;
  cacheKey?: unknown;
}

export const DataTableBody = <T extends {}>(props: DataTableBodyProps<T>) => {
  const { columns, dataSupplier, page, rowsPerPage, search, sx, onRowsTotalChange, cacheKey } = props;

  const [sortColumn, setSortColumn] = useState<string | undefined>();
  const [sortDir, setSortDir] = useState<SortDir>();

  const cols = useMemo<DataTableColumn<T>[]>(() => {
    return columns.map(column => ({
      ...column,
      name: column.name ?? "",
      sortable: column.sortable ?? true,
      sortName: column.sortName ?? column.name ?? "",
      render: column.render ?? ((row: T) => ((row as any)[column.name]))
    }))
  }, [columns])

  const data = useAsync((page: number, rowsPerPage: number, sortColumn: string | undefined, sortDir: SortDir | undefined, search: string, cacheKey?: unknown) => {
    return dataSupplier({
      page,
      size: rowsPerPage,
      orderColumn: sortColumn,
      orderDir: sortDir,
      search: search || undefined
    })
  }, [page, rowsPerPage, sortColumn, sortDir, search, cacheKey], {
    setLoading: state => ({ ...state, loading: true }),
  });
  
  useEffect(() => {
    data.currentPromise?.then(response => {
      onRowsTotalChange(response.recordsTotal!!);
    })
  }, [data.currentPromise, onRowsTotalChange]);

  const sortImg = useCallback((sortName: string) => {
    if (sortName !== sortColumn) {
      return "/img/ic_sort.svg";
    } else {
      if (sortDir === "desc") {
        return "/img/ic_sort_desc.svg";
      } else {
        return "/img/ic_sort_asc.svg";
      }
    }
  }, [sortColumn, sortDir])

  const changeSort = useCallback((sortName: string) => {
    if (sortName !== sortColumn) {
      setSortColumn(sortName);
      setSortDir("asc");
    } else if (sortDir === "asc") {
      setSortDir("desc");
    } else {
      setSortDir("asc");
    }
  }, [sortColumn, sortDir, setSortColumn, setSortDir])

  return (
    <Box sx={{
      position: "relative"
    }}>
      <Table sx={sx}>
        <TableHead>
          <TableRow sx={{
            backgroundColor: "#000000",
            height: "87px"
          }}>
            {cols.map(column => (
              <TableCell key={column.name} onClick={() => column.sortable && changeSort(column.sortName)} sx={{
                color: "#ffffff",
                cursor: column.sortable ? "pointer" : "inherit"
              }}>
                {column.sortable &&
                  <img src={sortImg(column.sortName)} alt="Sortieren" />
                }
                <ColumnLabel component="span">
                  {column.label}
                </ColumnLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.result &&
            data.result.data!!.map(row => (
              <TableRow key={(row as any).id} sx={{
                ":nth-child(odd)": {
                  backgroundColor: "#F5F5F3"
                }
              }}>
                {cols.map(column => (
                  <TableCell key={column.name}>
                    {column.render(row)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
          {data.error &&
            <TableRow>
              <TableCell colSpan={cols.length || 1}>
                Daten konnten nicht geladen werden
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      {data.loading &&
        <Box sx={{
          alignItems: "center",
          backgroundColor: "#ffffff80",
          bottom: 0,
          display: "flex",
          left: 0,
          justifyContent: "center",
          right: 0,
          top: 0,
          position: "absolute"
        }}>
          <CircularProgress />
        </Box>
      }
    </Box>
  )
}
import { Button, FormLabel as MuiFormLabel, IconButton, InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material"
import { Box, styled } from "@mui/system"
import { useState } from "react"

interface InputFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  textFieldProps?: TextFieldProps;
}

export const InputField = (props: InputFieldProps) => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      marginTop: "15px"
    }}>
      <MuiFormLabel sx={{
        fontSize: "12px",
        fontWeight: "bold"
      }}>{props.label}</MuiFormLabel>
      <TextField
        value={props.value}
        onChange={ev => props.onChange(ev.target.value)}
        {...props.textFieldProps}
        sx={{
          ...props.textFieldProps?.sx,
          "& div": {
            borderRadius: "2px"
          }
        }}
      />
    </Box>
  )
}

export const PasswordField = (props: InputFieldProps) => {
  const [passwordHidden, setPasswordHidden] = useState(true);

  return (
    <InputField
      {...props}
      textFieldProps={{
        ...props.textFieldProps,
        InputProps: {
          ...props.textFieldProps?.InputProps,
          type: passwordHidden ? "password" : "text",
          endAdornment: <InputAdornment position="end">
            <IconButton onClick={() => setPasswordHidden(!passwordHidden)}>
              <img src="/img/ic_showPassword.svg" alt="Passwort anzeigen" />
            </IconButton>
            </InputAdornment>
        }
      }}
    />
  )
}

export const SquareButton = styled(Button)(() => ({
  boxShadow: "0px 5px 10px #C4152899",
  height: "60px",
  width: "60px"
}))

export const DefaultButton = styled(Button)(() => ({
  boxShadow: "0px 5px 10px #C4152899",
  fontSize: "14px",
  fontWeight: "bold",
  height: "60px",
  width: "327px"
}))

export const CardHeadline = styled(Typography)(() => ({
  borderBottom: "1px solid #1D1D1B1A",
  color: "#333333",
  fontSize: "20px",
  fontWeight: "bold",
  padding: "29px",
  textTransform: "uppercase"
}))

export const FormHeadline = styled(Typography)(() => ({
  borderTop: "1px solid #1D1D1B1A",
  color: "#656569",
  fontSize: "16px",
  fontWeight: "bold",
  padding: "35px",
  textTransform: "uppercase"
}));

export const Form = styled(Box)(() => ({
  alignItems: "center",
  display: "grid",
  gridTemplateColumns: "1fr 3fr",
  margin: "30px"
}))

export const FormLabel = styled(Box)(() => ({
  color: "#333333",
  fontSize: "12px",
  fontWeight: "bold",
  marginBottom: "16px",
  marginTop: "16px"
}));

export const FormInput = (props: InputFieldProps) => {
  const { label, value, onChange, textFieldProps } = props;

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <TextField
        value={value}
        onChange={ev => onChange(ev.target.value)}
        {...textFieldProps}
        sx={{
          fontSize: "16px",
          height: "62px",
          "& input": {
            border: "2px solid #01020229",
            borderRadius: "2px",
          },
          ...textFieldProps?.sx
        }}
      />
    </>
  )
}

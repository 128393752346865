import { Box, Button, FormControlLabel, Radio, RadioGroup } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { Form, FormHeadline, FormLabel } from "../../../common/Controls"
import { FileUpload } from "../../../common/FileUpload"
import { PriceImageType, Raffle } from "../../../gen/api"
import Lottie from "react-lottie-player";


interface PriceImageProps {
  raffle: Raffle;
  onRaffleChange: (raffle: Raffle) => void;
}

export const PriceImage = (props: PriceImageProps) => {
  const { raffle, onRaffleChange } = props;

  const [priceImageFile, setPriceImageFile] = useState<File | null>(null);
  const [priceImageDataUrl, setPriceImageDataUrl] = useState<string | null>(null);
  const [animationData, setAnimationData] = useState<{} | null>(null);

  useEffect(() => {
    setPriceImageDataUrl(null);
    if (!priceImageFile) {
      return;
    }
    if (raffle.priceImageType === PriceImageType.Image) {
      const dataUrlReader = new FileReader();
      dataUrlReader.onload = () => setPriceImageDataUrl(dataUrlReader.result as string);
      dataUrlReader.readAsDataURL(priceImageFile);
    }
  }, [setPriceImageDataUrl, raffle, priceImageFile]);

  useEffect(() => {
    setAnimationData(null);
    if (raffle.priceImageType !== PriceImageType.Lottie) {
      return;
    }
    if (priceImageFile) {
        const stringReader = new FileReader();
        stringReader.onload = () => setAnimationData(JSON.parse(stringReader.result as string));
        stringReader.readAsText(priceImageFile);
    } else if (raffle.priceImageUrl) {
      fetch(raffle.priceImageUrl).then(response => {
        return response.json()
      }).then(text => {
        setAnimationData(text);
      }).catch(ex => {
        console.error("Failed to load lottie animation", ex);
      })
    }
  }, [setAnimationData, raffle, priceImageFile]);

  const deleteImage = useCallback(() => {
    if (priceImageFile) {
      setPriceImageFile(null);
      onRaffleChange({ ...raffle, priceImageRef: undefined } as any);
    } else {
      onRaffleChange({ ...raffle, priceImageUrl: undefined, priceImageRef: "" } as any);
    }
  }, [onRaffleChange, priceImageFile, raffle]);

  return (
    <>
      <FormHeadline>Gewinn - Grafik</FormHeadline>
      <Form>
        <FormLabel>Typ</FormLabel>
        <RadioGroup
          row
          value={raffle.priceImageType!!}
          onChange={ev => onRaffleChange({ ...raffle, priceImageType: ev.target.value as PriceImageType })}
        >
          <FormControlLabel value="IMAGE" control={<Radio />} label="Bild" />
          <FormControlLabel value="LOTTIE" control={<Radio />} label="Lottie-Animation" />
        </RadioGroup>
        <FormLabel>Bildauswahl</FormLabel>
        <FileUpload
          onFileChange={setPriceImageFile}
          onFileRefChange={priceImageRef => onRaffleChange({ ...raffle, priceImageRef } as any)}
          accept={raffle.priceImageType === "LOTTIE" ? ".json" : ".jpg,.png,.webp,.gif"}
        />
        <FormLabel>Vorschau</FormLabel>
        {(raffle.priceImageUrl || priceImageFile) && 
          <Box sx={{
            alignItems: "center",
            display: "flex",
            maxHeight: "200px",
            marginTop: "20px"
          }}>
            {raffle.priceImageType === PriceImageType.Image &&
              <img src={raffle.priceImageUrl ?? priceImageDataUrl!!} alt="Preisbild" style={{ height: "200px", width: "200px", objectFit: "contain" }} />
            }
            {animationData &&
              <Lottie
                play
                animationData={animationData}
                style={{ width: 150, height: 150 }}
              />
            }
            <Button onClick={deleteImage} sx={{
              marginLeft: "20px",
              fontWeight: "bold"
            }}>Bild löschen</Button>
          </Box>
        }
      </Form>
    </>
  )
}
import { Card } from "@mui/material";
import { CardHeadline, Form, FormHeadline, FormInput } from "../common/Controls";
import { OpticianDetailed } from "../gen/api";


interface MasterDataProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
}

export const MasterData = (props: MasterDataProps) => {
  const { optician, onOpticianChange } = props;

  return (
    <Card sx={{
      marginBottom: "25px"
    }}>
      <CardHeadline variant="h4">Stammdaten</CardHeadline>
      <FormHeadline>Anschrift</FormHeadline>
      <Form>
        <FormInput
          label="Name"
          value={optician.name!!}
          onChange={name => onOpticianChange({ ...optician, name })}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="Gewinnspiel-Pfad"
          value={optician.slug!!}
          onChange={slug => onOpticianChange({ ...optician, slug })}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="E-Mail"
          value={optician.email!!}
          onChange={email => onOpticianChange({ ...optician, email })}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="Anschrift"
          value={optician.streetAndNumber!!}
          onChange={streetAndNumber => onOpticianChange({ ...optician, streetAndNumber})}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="Postleitzahl"
          value={optician.zip!!}
          onChange={zip => onOpticianChange({ ...optician, zip})}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="Stadt"
          value={optician.city!!}
          onChange={city => onOpticianChange({ ...optician, city})}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
      </Form>
    </Card>
  )
}
import { Box, Popover } from "@mui/material"
import { useState } from "react";
import { SketchPicker } from "react-color";


interface ColorPickerProps {
  color: string;
  onColorChange: (color: string) => void;
}

export const ColorPicker = (props: ColorPickerProps) => {
  const { color, onColorChange } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openPicker = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <Box sx={{
      display: "flex"
    }}>
      <Box onClick={openPicker} sx={{
        border: "1px solid #E7EBEC",
        borderRadius: "2px",
        padding: "4px"
      }}>
        <Box sx={{
          backgroundColor: color,
          borderRadius: "2px",
          height: "19px",
          width: "19px"
        }}>
          {anchorEl !== null &&
            <Popover
              id="ColorPopover"
              open={anchorEl !== null}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <SketchPicker color={color} onChange={color => onColorChange(color.hex)} />
            </Popover>
          }
        </Box>
      </Box>
    </Box>
  )
}
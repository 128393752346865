import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useApi } from "../../../app/ApiProvider";
import { Form, FormInput, FormLabel } from "../../../common/Controls";
import { ImageUpload } from "../../../common/ImageUpload";
import { Price, PricePayload } from "../../../gen/api";
import {Quill} from "../../../common/Quill";


interface PriceDetailsProps {
  price: Price;
  onPriceChange: (price: Price) => void;
  onClose: () => void;
}

export const PriceDetails = (props: PriceDetailsProps) => {
  const { price: initialPrice, onPriceChange, onClose } = props;

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [price, setPrice] = useState<Price>(initialPrice);

  const save = useCallback(() => {
    api.updatePrice({ id: price.id, pricePayload: price as PricePayload }).then(price => {
      onPriceChange(price);
      onClose();
    }).catch(ex => {
      console.error("Failed to save price", ex);
      enqueueSnackbar("Preis konnte nicht gespeichert werden", { variant: "error" });
    })
  }, [api, price, onPriceChange, onClose, enqueueSnackbar]);

  return (
    <>
      <Form>
        <FormInput label="Titel" value={price.title!!} onChange={title => setPrice({ ...price, title: title})} />
        <FormLabel>Beschreibung</FormLabel>
        <Box sx={{ marginBottom: "24px", marginTop: "24px", width: "600px" }}>
          <Quill
            value={price.description}
            onChange={description => setPrice({ ...price, description })}
          />
        </Box>
        <ImageUpload
          label="Bild"
          payload={price}
          onPayloadChange={setPrice}
          urlKey="imageUrl"
          advice={<><Box component="span" sx={{ fontWeight: "bold" }}>Empfohlenes Bildformat:</Box> 4:3 mindestens 800 px * 600 px (B * H)</>}
        />
      </Form>
      <Box sx={{
        display: "flex",
        justifyContent: "end"
      }}>
        <Button variant="contained" onClick={onClose} sx={{
          backgroundColor: "white",
          color: "#000000CC",
          fontWeight: "bold",
          fontSize: "14px",
          height: "60px",
          marginRight: "30px",
          marginBottom: "16px",
          width: "280px"
        }}>Abbrechen</Button>
        <Button variant="contained" onClick={save} sx={{
          fontWeight: "bold",
          fontSize: "14px",
          height: "60px",
          marginRight: "30px",
          marginBottom: "16px",
          width: "280px"
        }}>Speichern</Button>
      </Box>
    </>
  )
}
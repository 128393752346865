import { Form, FormHeadline } from "../../../common/Controls";
import {OpticianDesign, OpticianDesignPayload} from "../../../gen/api";
import {FontPicker} from "./FontPicker";

interface FontsProps {
  design: OpticianDesign;
  onDesignChange: (design: OpticianDesign) => void;
}

export const Fonts = (props: FontsProps) => {
  const { design, onDesignChange } = props;

  return (
    <>
      <FormHeadline>Typografie</FormHeadline>
      <Form>
        <FontPicker
          label="Überschrift"
          fontName={design.headerFont!!}
          onFontNameChange={headerFont => onDesignChange({ ...design, headerFont })}
          fontUrl={design.headerFontUrl}
          fontFileRef={(design as OpticianDesignPayload).headerFontFileRef}
          onFontFileRefChange={headerFontFileRef => onDesignChange({ ...design, headerFontFileRef } as any)}
        />
        <FontPicker
          label="Fließtext"
          fontName={design.bodyFont!!}
          onFontNameChange={bodyFont => onDesignChange({ ...design, bodyFont })}
          fontUrl={design.bodyFontUrl}
          fontFileRef={(design as OpticianDesignPayload).bodyFontFileRef}
          onFontFileRefChange={bodyFontFileRef => onDesignChange({ ...design, bodyFontFileRef } as any)}
        />
      </Form>
    </>
  )
}
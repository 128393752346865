import {FormLabel} from "../../../common/Controls";
import {
  Box
} from "@mui/material";
import {FileUpload} from "../../../common/FileUpload";

export const FontPicker = (props: {
  label: string;
  fontName: string,
  onFontNameChange: (fontName: string) => void,
  fontUrl?: string,
  fontFileRef?: string,
  onFontFileRefChange: (fontFileRef: string | null) => void;
}) => {
  const { label, onFontFileRefChange } = props;

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Box sx={{
        marginBottom: "24px"
      }}>
        <FileUpload onFileRefChange={onFontFileRefChange} accept=".woff,.woff2,.otf,.ttf" />
      </Box>
    </>
  )
}
import { Box, Switch } from "@mui/material";
import { useCallback } from "react";
import { Discount, OpticianDetailed } from "../../../gen/api";
import { FormLabel, Form, FormHeadline } from "../../../common/Controls";
import { styled } from "@mui/system";
import {Quill} from "../../../common/Quill";


interface DiscountDetailsProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
}

const StyledQuill = styled(Quill)(() => ({
  marginBottom: "12px",
  marginTop: "12px",
  "& .ql-editor": {
    minHeight: "150px"
  }
}))

export const DiscountDetails = (props: DiscountDetailsProps) => {
  const { optician, onOpticianChange } = props;

  const discount = optician.discount!!;
  const onDiscountChange = useCallback((discount: Discount) => {
    onOpticianChange({ ...optician, discount });
  }, [onOpticianChange, optician])

  return (
    <Box>
      <Box sx={{
        backgroundColor: "#F7F7F7",
        display: "flex",
        padding: "30px 28px"
      }}>
        <Box sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E7EBEC",
          padding: "16px"
        }}>
          <Switch checked={discount.active} onChange={ev => onDiscountChange({ ...discount, active: ev.target.checked })} />
          Rabatt-Aktion aktivieren
        </Box>
      </Box>
      {discount.active &&
        <>
          <FormHeadline>Rabatt</FormHeadline>
          <Form>
            <FormLabel>Rabatt-Aufforderung</FormLabel>
            <StyledQuill
              value={discount.discountInvitation}
              onChange={discountInvitation => onDiscountChange({ ...discount, discountInvitation })}
            />
            <FormLabel>E-Mail</FormLabel>
            <StyledQuill
              value={discount.emailText}
              onChange={emailText => onDiscountChange({ ...discount, emailText })}
            />
          </Form>
        </>
      }
    </Box>
  )
}
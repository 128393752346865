import { InputAdornment, MenuItem, Select, TextField } from "@mui/material"
import { Box } from "@mui/system"


interface DataTableHeaderProps {
  rowsPerPage: number;
  onRowsPerPageChange: (rowsPerPage: number) => void;
  search: string;
  onSearchChange: (search: string) => void;
}

export const DataTableHeader = (props: DataTableHeaderProps) => {
  const { rowsPerPage, onRowsPerPageChange, search, onSearchChange } = props;

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "60px"
    }}>
      <TextField
        label="Inhalte filtern"
        value={search}
        onChange={ev => onSearchChange(ev.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end"><img src="/img/ic_search.svg" alt="" /></InputAdornment>
        }}
        sx={{
          backgroundColor: "white",
          width: "374px"
        }}
      />
      <Select value={rowsPerPage} onChange={ev => onRowsPerPageChange(ev.target.value as number)}>
        {[10, 25, 100].map(n => (
          <MenuItem value={n}>{n} Einträge pro Seite</MenuItem>
        ))}
      </Select>
    </Box>
  )
}
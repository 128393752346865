import { Box, styled } from "@mui/system"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"


interface NavButtonProps {
  to: string;
  active: boolean;
}

const SimpleLink = styled(Link)(() => ({
  textDecoration: "none"
}));

export const NavBarButton = styled(Box)(() => ({
  alignItems: "center",
  boxSizing: "border-box",
  color: "white",
  display: "flex",
  fontSize: "12px",
  fontWeight: "bold",
  height: "60px",
  margin: "6px",
  padding: "12px",
  textDecoration: "none !important",
  textTransform: "uppercase",
  "& > img": {
    marginRight: "16px"
  }
}));

export const NavButton = (props: PropsWithChildren<NavButtonProps>) => {

  return (
    <SimpleLink to={props.to}>
      <NavBarButton sx={{
        backgroundColor: props.active ? "#0000004D" : "inherit"
      }}>
        {props.children}
      </NavBarButton>
    </SimpleLink>
  )
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { useCallback, useState } from "react";
import { useApi } from "../../../app/ApiProvider"
import { DataTable } from "../../../common/data_table/DataTable"
import { PaginationRequest } from "../../../common/data_table/DataTableBody";
import { OpticianDetailed, Price } from "../../../gen/api";
import { CsvUpload } from "./CsvUpload";
import { PriceDetails } from "./PriceDetails";
import htmlReactParse from "html-react-parser";
import { useSnackbar } from "notistack";


interface PricesProps {
  optician: OpticianDetailed;
}

export const Prices = (props: PricesProps) => {
  const { optician } = props;

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [editedPrice, setEditedPrice] = useState<Price | null>(null);
  const [priceToDelete, setPriceToDelete] = useState<Price | null>(null);
  const [lastChange, setLastChange] = useState<Date>(new Date());

  const dataSupplier = useCallback((request: PaginationRequest) => {
    return api.getPricesByOptician({
      id: optician.id,
      ...request
    })
  }, [api, optician]);

  const deletePrice = useCallback(() => {
    api.deletePrice({ id: priceToDelete!!.id }).then(() => {
      setLastChange(new Date());
      enqueueSnackbar("Preis wurde gelöscht", { variant: "success" });
      setPriceToDelete(null);
    }).catch(ex => {
      console.error("Failed to delete price", ex);
      enqueueSnackbar("Preis konnte nicht gelöscht werden", ex);
    })
  }, [api, priceToDelete, setLastChange, enqueueSnackbar]);

  return (
    <Box>
      <CsvUpload
        optician={optician}
        onPricesUpdate={() => setLastChange(new Date())}
      />
      <Box sx={{
        margin: "30px 40px"
      }}>
        <DataTable
          dataSupplier={dataSupplier}
          cacheKey={lastChange}
          columns={[
            {
              name: "priceId",
              label: "ID"
            },
            {
              label: "Bild",
              sortable: false,
              render: price => (
                <>
                  {price.imageUrl &&
                    <img src={price.imageUrl} alt="" style={{ objectFit: "contain", height: "100px", width: "100px" }} />
                  }
                </>
              )
            },
            {
              name: "title",
              label: "Titel"
            },
            {
              name: "description",
              label: "Beschreibung",
              render: price => (
                htmlReactParse(price.description ?? "")
              )
            },
            {
              label: "",
              render: price => (
                <Box sx={{
                  display: "flex",
                  justifyContent: "end"
                }}>
                  <IconButton onClick={() => setEditedPrice(price)}>
                    <img src="/img/table_edit.svg" alt="Bearbeiten" />
                  </IconButton>
                  <IconButton onClick={() => setPriceToDelete(price)}>
                    <img src="/img/table_delete.svg" alt="Löschen" />
                    </IconButton>
                </Box>
              ),
              sortable: false
            }
          ]}
        />
      </Box>
      <Dialog open={editedPrice !== null} onClose={() => setEditedPrice(null)} maxWidth="lg">
          <DialogTitle>{editedPrice?.title}</DialogTitle>
          <DialogContent>
            <PriceDetails
              price={editedPrice!!}
              onPriceChange={() => setLastChange(new Date())}
              onClose={() => setEditedPrice(null)}
            />
          </DialogContent>
      </Dialog>
      <Dialog open={priceToDelete !== null} onClose={() => setPriceToDelete(null)}>
          <DialogTitle>{priceToDelete?.priceId} löschen?</DialogTitle>
          <DialogContent>
            Soll der Preis "{priceToDelete?.title}" wirklich gelöscht werden?
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setPriceToDelete(null)}>Abbrechen</Button>
            <Button variant="contained" onClick={deletePrice}>Löschen</Button>
          </DialogActions>
      </Dialog>
    </Box>
  )
}
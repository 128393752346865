import { Box, Button } from "@mui/material"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { FormLabel } from "./Controls"
import { FileUpload } from "./FileUpload"


interface ImageUploadProps<T> {
  label: string;
  payload: T;
  urlKey: (keyof T) & string;
  onPayloadChange: (payload: T) => void;
  advice?: ReactNode;
}

export const ImageUpload = <T extends {}>(props: ImageUploadProps<T>) => {
  const { label, payload, urlKey, onPayloadChange, advice } = props;

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!imageFile) {
      setImageDataUrl(null);
      return;
    }
    const fr = new FileReader();
    fr.onload = () => setImageDataUrl(fr.result as string);
    fr.readAsDataURL(imageFile);
  }, [imageFile]);

  const fileRefKey = useMemo(() => {
    if (!urlKey.endsWith("Url")) {
      throw new Error(`Url key ${urlKey} must end with "Url"`);
    }
    return urlKey.substring(0, urlKey.length - 3) + "Ref";
  }, [urlKey]);

  const onFileRefChange = useCallback((ref: string | null) => {
    onPayloadChange({ ...payload, [fileRefKey]: ref })
  }, [onPayloadChange, fileRefKey, payload]);

  const previewUrl = useMemo(() => {
    if (imageDataUrl) {
      return imageDataUrl;
    }
    const url = payload[urlKey];
    if (url) {
      return url as unknown as string;
    }
    return null;
  }, [payload, urlKey, imageDataUrl]);

  const deleteImage = useCallback(() => {
    if (imageFile) {
      setImageFile(null);
      onFileRefChange(null);
    } else {
      onPayloadChange({ ...payload, [urlKey]: null, [fileRefKey]: "" });
    }
  }, [imageFile, setImageFile, onFileRefChange, onPayloadChange, payload, urlKey, fileRefKey]);

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Box>
        <FileUpload
          onFileChange={setImageFile}
          onFileRefChange={onFileRefChange}
          accept={".jpg,.png,.webp,.gif"}
        />
        <Box sx={{
          color: "#656569",
          fontSize: "14px",
          paddingTop: "12px"
        }}>
          {advice}
        </Box>
      </Box>
      <FormLabel>Vorschau</FormLabel>
      {previewUrl && 
        <Box sx={{
          display: "flex"
        }}>
          <img style={{ height: "200px", objectFit: "contain", width: "200px" }} src={previewUrl} alt="" />
          <Button onClick={deleteImage} sx={{
            fontWeight: "bold",
            marginLeft: "28px"
          }}>Bild löschen</Button>
        </Box>
      }
      {!previewUrl && <div />}
    </>
  )
}
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { PropsWithChildren } from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C41528"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: "2px",
          minHeight: "60px"
        }
      }
    }
  }
})

export const CustomTheme = (props: PropsWithChildren<{}>) => {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import { OpticianDetailed } from "../../gen/api";


interface RaffleLinkProps {
  optician: OpticianDetailed;
}

export const RaffleLink = (props: RaffleLinkProps) => {
  const { optician } = props;
  const { enqueueSnackbar } = useSnackbar();

  const link = useMemo(() => {
    return `https://optikergewinnspiel.de/${optician.slug}`;
  }, [optician]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link).then(() => {
      enqueueSnackbar("Link kopiert");
    })
  }, [enqueueSnackbar, link]);

  return (
    <Box sx={{
      alignItems: "center",
      backgroundColor: "#F7F7F7",
      border: "1px solid #E7EBEC",
      boxSizing: "border-box",
      display: "flex",
      height: "60px",
      justifyContent: "space-between",
      padding: "10px",
      width: "413px"
    }}>
      <Box sx={{
        flexShrink: 1,
        overflow: "hidden"
      }}>
        <Box sx={{
          color: "#00000057",
          fontSize: "12px",
          fontWeight: "bold"
        }}>Link zum Gewinnspiel</Box>
        <Box sx={{
          color: "#000000CC",
          fontSize: "16px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }}>{link}</Box>
      </Box>
      <IconButton onClick={copyLink}>
        <img src="/img/ic_copy_link.svg" alt="Link kopieren" />
      </IconButton>
    </Box>
  )
}
import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ColorPicker } from "../../../common/ColorPicker";
import { Form, FormHeadline, FormLabel } from "../../../common/Controls";
import { ImageUpload } from "../../../common/ImageUpload";
import { ImageRepeat, OpticianDesign } from "../../../gen/api";


interface BackgroundProps {
  design: OpticianDesign;
  onDesignChange: (design: OpticianDesign) => void;
}

export const Background = (props: BackgroundProps) => {
  const { design, onDesignChange } = props;

  return (
    <>
      <FormHeadline>Hintergrund</FormHeadline>
      <Form>
        <FormLabel>Hintergrundfarbe</FormLabel>
        <Box sx={{ marginBottom: "28px", marginTop: "28px" }}>
          <ColorPicker
            color={design.backgroundColor!!}
            onColorChange={backgroundColor => onDesignChange({ ...design, backgroundColor })}
          />
        </Box>
        <ImageUpload
          label="Hintergrundbild"
          payload={design}
          onPayloadChange={onDesignChange}
          urlKey="backgroundImageUrl"
          advice={<><Box component="span" sx={{ fontWeight: "bold" }}>Empfohlenes Bildformat:</Box> 16:9 mindestens 1920 px * 1080 px (B * H)</>}
        />
        <FormLabel>Darstellung</FormLabel>
        <RadioGroup
          row
          value={design.backgroundImageRepeat!!}
          onChange={ev => onDesignChange({ ...design, backgroundImageRepeat: ev.target.value as ImageRepeat })}
        >
          <FormControlLabel value="NONE" control={<Radio />} label="Vollflächig" />
          <FormControlLabel value="REPEAT" control={<Radio />} label="Muster" />
        </RadioGroup>
      </Form>
    </>
  );
}
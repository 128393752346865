import React, { PropsWithChildren, useContext, useMemo } from "react";
import { Configuration, DefaultApi } from "../gen/api";
import { apiBaseUrl } from "./Settings";

const ApiContext = React.createContext(new DefaultApi());

export const useApi = () => useContext(ApiContext);

interface ApiProviderProps {
  sessionToken?: string;
  refreshSession?: () => Promise<string | null>;
  sessionExpiresAt?: Date;
}

export const ApiProvider = (props: PropsWithChildren<ApiProviderProps>) => {

  const { sessionToken, refreshSession, sessionExpiresAt } = props;

  const authenticatedApi = useMemo(() => {
    return new DefaultApi(new Configuration({
      basePath: apiBaseUrl,
      fetchApi: (input, init) => 
        fetch(input, {
          ...init,
          headers: {
            ...init?.headers,
            ...(sessionToken && { session: sessionToken } )
          }
        }).then(response => {
          if (response.status === 401 && sessionExpiresAt && refreshSession && new Date() >= sessionExpiresAt) {
            return refreshSession().then(newSessionToken => {
              if (!newSessionToken) {
                return response;
              }
              return fetch(input, {
                ...init,
                headers: {
                  ...init?.headers,
                  session: newSessionToken
                }
              })
            });
          } else {
            return response;
          }
        })
    }))
  }, [sessionToken, refreshSession, sessionExpiresAt]);

  return (
    <ApiContext.Provider value={authenticatedApi}>
      {props.children}
    </ApiContext.Provider>
  )
}

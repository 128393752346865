import {Box, CircularProgress} from "@mui/material"
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useApi } from "../../../app/ApiProvider"
import {LegalTexts, OpticianDetailed} from "../../../gen/api";
import {Form, FormHeadline, FormLabel} from "../../../common/Controls";
import {styled} from "@mui/system";
import {Quill} from "../../../common/Quill";

interface ParticipationProps {
  optician: OpticianDetailed;
  legalTexts: LegalTexts | undefined;
  onLegalTextsChange: (legalTexts: LegalTexts) => void;
}

const StyledQuill = styled(Quill)(() => ({
  marginBottom: "12px",
  marginTop: "12px",
  "& .ql-editor": {
    minHeight: "150px"
  }
}))

export const Legal = (props: ParticipationProps) => {
  const { optician, legalTexts, onLegalTextsChange } = props;
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (legalTexts) {
      return;
    }
    api.getLegalTextsByOptician({ id: optician.id }).then(l => {
      onLegalTextsChange(l);
    }).catch(ex => {
      console.error("Failed to load legal texts", ex);
      enqueueSnackbar("Rechtliche Texte konnten nicht geladen werden", { variant: "error" });
      setError(true);
    })
  }, [api, optician, enqueueSnackbar, setError, legalTexts, onLegalTextsChange]);

  if (!legalTexts) {
    if (!error) {
      return <CircularProgress />
    } else {
      return <div>Rechtliche Texte konnten nicht geladen werden</div>
    }
  }

  return (
    <Box>
      <FormHeadline>Rechtliche Texte</FormHeadline>
      <Form>
        <FormLabel>Teilnahmebedingungen</FormLabel>
        <StyledQuill
          value={legalTexts.participation ?? ""}
          onChange={participation => onLegalTextsChange({ ...legalTexts, participation })}
        />
        <FormLabel>Datenschutzerklärung</FormLabel>
        <StyledQuill
          value={legalTexts.dataPrivacy ?? ""}
          onChange={dataPrivacy => onLegalTextsChange({ ...legalTexts, dataPrivacy })}
        />
        <FormLabel>Impressum</FormLabel>
        <StyledQuill
          value={legalTexts.imprint ?? ""}
          onChange={imprint => onLegalTextsChange({ ...legalTexts, imprint })}
        />
      </Form>
    </Box>
  )
}
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ApiProvider, useApi } from "../app/ApiProvider"
import { Session } from "../gen/api";

export const AuthenticationRequired = (props: PropsWithChildren<{}>) => {
  const history = useHistory();
  const location = useLocation();
  const api = useApi();

  const sessionStr = localStorage.getItem("session");
  const [session, setSession] = useState<Session | null>(sessionStr ? JSON.parse(sessionStr) : null);

  useEffect(() => {
    const sessionStr = localStorage.getItem("session");
    setSession(sessionStr ? JSON.parse(sessionStr) : null);
  }, [setSession]);

  const refreshSession = useCallback(() => {
    return api.refreshSession({ refreshToken: session?.refreshToken ?? "" }).then(newSession => {
      localStorage.setItem("session", JSON.stringify(newSession));
      setSession(newSession);
      return newSession.sessionToken;
    }).catch(ex => {
      console.error("Failed to refresh sessoin", ex);
      setSession(null);
      return null;
    })
  }, [api, session]);

  useEffect(() => {
    if (!session) {
      history.replace("/login?redirectUrl=" + encodeURIComponent(location.pathname))
    }
  }, [session, history, location]);

  return (
    <ApiProvider
      sessionToken={session?.sessionToken}
      refreshSession={refreshSession}
      sessionExpiresAt={session ? new Date(session?.expiresAt) : undefined}
    >
      {props.children}
    </ApiProvider>
  )
}
import { Box, Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useApi } from "../../../app/ApiProvider";
import { OpticianDetailed } from "../../../gen/api";


interface CsvUploadProps {
  optician: OpticianDetailed;
  onPricesUpdate: () => void;
}

export const CsvUpload = (props: CsvUploadProps) => {
  const { optician, onPricesUpdate } = props;

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [uploading, setUploading] = useState(false);

  const upload = useCallback((file: File | undefined) => {
    if (!file) {
      return;
    }
    setUploading(true);
    api.updatePricesByOpticianWithCsv({
      id: optician.id,
      body: file
    }).then(() => {
      enqueueSnackbar("CSV Upload erfolgreich", { variant: "success" });
      setUploading(false);
      onPricesUpdate();
    }).catch(ex => {
      console.error("Price csv upload failed", ex);
      enqueueSnackbar("CSV Upload fehlgeschlagen", { variant: "error" });
      setUploading(false);
    });
  }, [setUploading, api, optician, enqueueSnackbar, onPricesUpdate])

  return (
    <Box sx={{
      backgroundColor: "#F7F7F7",
      border: "1px solid #E7EBEC"
    }}>
      <Button variant="contained" component="label" sx={{
        boxShadow: "0px 5px 10px #C4152899",
        margin: "30px 24px"
      }}>
        {!uploading && <input
            type="file"
            accept=".csv"
            hidden
            onChange={ev => upload(ev.target.files?.[0])}
          />}
          {uploading && <CircularProgress sx={{
            color: "white"
          }} />}
          {!uploading && "CSV-Datei hochladen"}
        </Button>
    </Box>
  )
}
import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Form, FormHeadline, FormInput } from "../../../common/Controls";
import { CustomWinnerField } from "../../../gen/api";


interface CustomFieldsProps {
  index: number;
  customWinnerField: CustomWinnerField;
  onCustomWinnerFieldChange: (customWinnerField: CustomWinnerField) => void;
  onDelete: () => void;
}

export const CustomField = (props: CustomFieldsProps) => {
  const { index, customWinnerField, onCustomWinnerFieldChange, onDelete } = props;

  return (
    <Box sx={{
      backgroundColor: "#F7F7F7",
      border: "1px solid #E7EBEC",
      marginBottom: "24px",
      padding: "30px 16px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <FormHeadline sx={{
          borderTop: "none",
          padding: 0
        }}>Angabe #{index + 1}</FormHeadline>
        <Button variant="contained" onClick={onDelete} sx={{
          marginBottom: "12px"
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g id="ic_delete" transform="translate(16 16)" opacity="0.8">
              <g id="ic_delete-2" data-name="ic_delete" transform="translate(-16 -16)">
                <rect id="Rechteck_2356" data-name="Rechteck 2356" width="32" height="32" fill="none" />
                <path fill="white" id="Pfad_4152" data-name="Pfad 4152" d="M24,9H20.2l-.8-2.3a1,1,0,0,0-.9-.7h-5a1,1,0,0,0-.9.7L11.8,9H8a1,1,0,0,0,0,2H9V22.6A3.4,3.4,0,0,0,12.4,26h7.3A3.4,3.4,0,0,0,23,22.6V11h1a1,1,0,0,0,0-2ZM14.2,8h3.6l.3,1H13.9Zm5.3,16h-7A1.5,1.5,0,0,1,11,22.5V11H21V22.5A1.5,1.5,0,0,1,19.5,24Z"/>
              </g>
            </g>
          </svg>
        </Button>
      </Box>
      <Form sx={{
        margin: 0
      }}>
        <FormInput
          label="Beschreibung"
          value={customWinnerField.name!!}
          onChange={name => onCustomWinnerFieldChange({ ...customWinnerField, name })}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormInput
          label="Platzhalter"
          value={customWinnerField.placeholder!!}
          onChange={placeholder => onCustomWinnerFieldChange({ ...customWinnerField, placeholder })}
          textFieldProps={{
            sx: {
              marginBottom: "12px"
            }
          }}
        />
        <FormLabel>Typ</FormLabel>

        <RadioGroup
          row
          value={customWinnerField.required!!}
          onChange={ev => onCustomWinnerFieldChange({ ...customWinnerField, required: ev.target.value as unknown as boolean})}
        >
          <FormControlLabel value={false} control={<Radio />} label="Optional" />
          <FormControlLabel value={true} control={<Radio />} label="Pflichteingabe" />
        </RadioGroup>
      </Form>
    </Box>
  )
}
import { ColorPicker } from "../../../common/ColorPicker";
import { Form, FormHeadline, FormLabel } from "../../../common/Controls"
import { OpticianDesign } from "../../../gen/api";

interface ColorsProps {
  design: OpticianDesign;
  onDesignChange: (design: OpticianDesign) => void;
}

export const Colors = (props: ColorsProps) => {
  const { design, onDesignChange } = props;

  return (
    <>
      <FormHeadline>Farben</FormHeadline>
      <Form>
        <FormLabel>Hauptfarbe für Icons, Buttons und Links</FormLabel>
        <ColorPicker
          color={design.primaryColor!!}
          onColorChange={primaryColor => onDesignChange({ ...design, primaryColor})}
        />
        <FormLabel>Textfarbe Button</FormLabel>
        <ColorPicker
          color={design.buttonColor!!}
          onColorChange={buttonColor => onDesignChange({ ...design, buttonColor })}
        />
        <FormLabel>Überschriften</FormLabel>
        <ColorPicker
          color={design.headlineColor!!}
          onColorChange={headlineColor => onDesignChange({ ...design, headlineColor })}
        />
        <FormLabel>Textfarbe</FormLabel>
        <ColorPicker
          color={design.fontColor!!}
          onColorChange={fontColor => onDesignChange({ ...design, fontColor })}
        />
      </Form>
    </>
  )
}
import { Button } from "@mui/material"
import { Box, styled } from "@mui/system"
import { useMemo } from "react";


interface PaginationProps {
  page: number;
  rowsTotal: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
}

const PaginationButton = styled(Button)(() => ({
  backgroundColor: "white",
  borderRadius: "4px",
  color: "black",
  fontWeight: "bold",
  height: "50px",
  margin: "2px",
  minWidth: "50px",
  padding: "20px",
  ":disabled": {
    backgroundColor: "#ffffff00"
  }
}));

const range = (from: number, to: number): number[] => {
  if (from >= to) {
    return [];
  }
  return Array.from(Array(to - from).keys()).map(i => i + from);
}

const pageRadius = 2;

export const DataTablePagination = (props: PaginationProps) => {
  const { page, rowsTotal, rowsPerPage, onPageChange } = props;

  const totalPages = useMemo(() => {
    return Math.max(1, Math.ceil(rowsTotal / rowsPerPage));
  }, [rowsTotal, rowsPerPage]);

  const showFirstButton = useMemo(() => {
    return page > 0;
  }, [page]);

  const showFirstGap = useMemo(() => {
    return page - pageRadius > 1;
  }, [page]);

  const prevButtons = useMemo(() => {
    const start = Math.max(page - pageRadius, 1);
    return range(start, page);
  }, [page]);

  const nextButtons = useMemo(() => {
    const end = Math.min(page + pageRadius + 1, totalPages - 1);
    return range(page + 1, end);
  }, [page, totalPages]);

  const showLastGap = useMemo(() => {
    return page + pageRadius < totalPages - 2;
  }, [page, totalPages]);

  const showLastButton = useMemo(() => {
    return page < totalPages - 1;
  }, [page, totalPages]);

  return (
    <Box>
      <PaginationButton disabled={page === 0} onClick={() => onPageChange(page - 1)}>Zurück</PaginationButton>
      {showFirstButton &&
        <PaginationButton onClick={() => onPageChange(0)}>1</PaginationButton>
      }
      {showFirstGap && "..."}
      {prevButtons.map(i => (
        <PaginationButton onClick={() => onPageChange(i)}>{i + 1}</PaginationButton>
      ))}
      <PaginationButton sx={{
        backgroundColor: "#000000CC",
        color: "white",
        ":hover": {
          backgroundColor: "#000000AA"
        }
      }}>{page + 1}</PaginationButton>
      {nextButtons.map(i => (
        <PaginationButton onClick={() => onPageChange(i)}>{i + 1}</PaginationButton>
      ))}
      {showLastGap && "..."}
      {showLastButton &&
        <PaginationButton onClick={() => onPageChange(totalPages - 1)}>{totalPages}</PaginationButton>
      }
      <PaginationButton disabled={page === totalPages - 1} onClick={() => onPageChange(page + 1)}>Weiter</PaginationButton>
    </Box>
  )
}
import ReactQuill from "react-quill";


export const Quill = (props: {
  value: string | undefined,
  onChange: (value: string) => void
}) => {
  const { value, onChange } = props;

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      [{'align': []}, {'color': []}, {'background': []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      ['clean']
    ],
  };

  return (
    <ReactQuill value={value} onChange={onChange} modules={modules} />
  )
}
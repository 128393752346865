import { Theme } from "@mui/material"
import { Box, SxProps } from "@mui/system";
import {useEffect, useState} from "react"
import { DataSupplier, DataTableBody, DataTableColumnDef } from "./DataTableBody";
import { DataTableFooter } from "./DataTableFooter";
import { DataTableHeader } from "./DataTableHeader";


interface DataTableProps<T> {
  columns: DataTableColumnDef<T>[];
  dataSupplier: DataSupplier<T>;
  tableSx?: SxProps<Theme>;
  cacheKey?: unknown;
}

export const DataTable = <T extends {}>(props: DataTableProps<T>) => {
  const { columns, dataSupplier, tableSx, cacheKey } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [rowsTotal, setRowsTotal] = useState(0);

  useEffect(() => {
    if (search !== "") {
      setPage(0);
    }
  }, [search])

  return (
    <Box>
      <DataTableHeader
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={setRowsPerPage}
        search={search}
        onSearchChange={setSearch}
      />
      <DataTableBody
        columns={columns}
        dataSupplier={dataSupplier}
        sx={tableSx}
        page={page}
        onRowsTotalChange={setRowsTotal}
        rowsPerPage={rowsPerPage}
        search={search}
        cacheKey={cacheKey}
      />
      <DataTableFooter
        page={page}
        rowsTotal={rowsTotal}
        rowsPerPage={rowsPerPage}
        onPageChange={setPage}
      />
    </Box>
  )
}
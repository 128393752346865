/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImageRepeat,
    ImageRepeatFromJSON,
    ImageRepeatFromJSONTyped,
    ImageRepeatToJSON,
} from './';

/**
 * 
 * @export
 * @interface OpticianDesign
 */
export interface OpticianDesign {
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    backgroundColor?: string;
    /**
     * 
     * @type {ImageRepeat}
     * @memberof OpticianDesign
     */
    backgroundImageRepeat?: ImageRepeat;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    backgroundImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    bodyFont?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    bodyFontUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    buttonColor?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    fontColor?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    headerFont?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    headerFontUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    headlineColor?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    primaryColor?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    waitingAnimation?: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianDesign
     */
    waitingAnimationRef?: string;
    /**
     * 
     * @type {number}
     * @memberof OpticianDesign
     */
    waitingTime?: number;
}

export function OpticianDesignFromJSON(json: any): OpticianDesign {
    return OpticianDesignFromJSONTyped(json, false);
}

export function OpticianDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpticianDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': !exists(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'backgroundImageRepeat': !exists(json, 'backgroundImageRepeat') ? undefined : ImageRepeatFromJSON(json['backgroundImageRepeat']),
        'backgroundImageUrl': !exists(json, 'backgroundImageUrl') ? undefined : json['backgroundImageUrl'],
        'bodyFont': !exists(json, 'bodyFont') ? undefined : json['bodyFont'],
        'bodyFontUrl': !exists(json, 'bodyFontUrl') ? undefined : json['bodyFontUrl'],
        'buttonColor': !exists(json, 'buttonColor') ? undefined : json['buttonColor'],
        'fontColor': !exists(json, 'fontColor') ? undefined : json['fontColor'],
        'headerFont': !exists(json, 'headerFont') ? undefined : json['headerFont'],
        'headerFontUrl': !exists(json, 'headerFontUrl') ? undefined : json['headerFontUrl'],
        'headlineColor': !exists(json, 'headlineColor') ? undefined : json['headlineColor'],
        'id': json['id'],
        'logoUrl': !exists(json, 'logoUrl') ? undefined : json['logoUrl'],
        'primaryColor': !exists(json, 'primaryColor') ? undefined : json['primaryColor'],
        'waitingAnimation': !exists(json, 'waitingAnimation') ? undefined : json['waitingAnimation'],
        'waitingAnimationRef': !exists(json, 'waitingAnimationRef') ? undefined : json['waitingAnimationRef'],
        'waitingTime': !exists(json, 'waitingTime') ? undefined : json['waitingTime'],
    };
}

export function OpticianDesignToJSON(value?: OpticianDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backgroundColor': value.backgroundColor,
        'backgroundImageRepeat': ImageRepeatToJSON(value.backgroundImageRepeat),
        'backgroundImageUrl': value.backgroundImageUrl,
        'bodyFont': value.bodyFont,
        'bodyFontUrl': value.bodyFontUrl,
        'buttonColor': value.buttonColor,
        'fontColor': value.fontColor,
        'headerFont': value.headerFont,
        'headerFontUrl': value.headerFontUrl,
        'headlineColor': value.headlineColor,
        'id': value.id,
        'logoUrl': value.logoUrl,
        'primaryColor': value.primaryColor,
        'waitingAnimation': value.waitingAnimation,
        'waitingAnimationRef': value.waitingAnimationRef,
        'waitingTime': value.waitingTime,
    };
}



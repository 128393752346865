import { Box } from "@mui/material";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { useApi } from "../../../app/ApiProvider";
import { DataTable } from "../../../common/data_table/DataTable";
import { PaginationRequest } from "../../../common/data_table/DataTableBody";
import { OpticianDetailed } from "../../../gen/api";
import { CsvUpload } from "./CsvUpload";


interface RaffleCodesProps {
  optician: OpticianDetailed;
}

export const RaffleCodes = (props: RaffleCodesProps) => {
  const { optician } = props;

  const api = useApi();

  const [lastUpdatedAt, setLastUpdatedAt] = useState(new Date());

  const dataSupplier = useCallback((request: PaginationRequest) => {
    return api.getRaffleCodesByOptician({
      ...request,
      id: optician.id
    });
  }, [api, optician]);

  return (
    <Box>
      <CsvUpload optician={optician} onRaffleCodesUpdate={() => setLastUpdatedAt(new Date())} />
      <Box sx={{
        margin: "30px"
      }}>
        <DataTable
          dataSupplier={dataSupplier}
          columns={[
            {
              name: "codeNumber",
              label: "Code-Nummer"
            },
            {
              label: "Typ",
              name: "price",
              render: raffleCode => (
                <>
                  {raffleCode.price ? "Gewinn" : "Niete"}
                </>
              )
            },
            {
              label: "Eingelöst",
              sortable: false,
              render: raffleCode => (
                <>
                  {raffleCode.usedAt && <img src="/img/ic_table_checkmark.svg" alt="Eingelöst" />}
                </>
              )
            },
            {
              label: "Einlösedatum",
              name: "usedAt",
              render: raffleCode => raffleCode.usedAt ? format(raffleCode.usedAt, "dd.MM.yyyy - HH:mm") : ""
            },
            {
              label: "Eingelöst von",
              sortName: "usedBy.givenName",
              render: raffleCode => raffleCode.usedBy ? `${raffleCode.usedBy.givenName} ${raffleCode.usedBy.surname}` : ""
            },
            {
              label: "Gewinn",
              sortName: "price.priceId",
              render: raffleCode => raffleCode.price?.priceId
            }
          ]}
          cacheKey={lastUpdatedAt}
        />
      </Box>
    </Box>
  )
}
import { Button, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useApi } from "../app/ApiProvider"
import { InputField, PasswordField } from "../common/Controls";

export const Login = () => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { redirectUrl } = useParams<{ redirectUrl?: string }>();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = useCallback(() => {
    api.login({ loginPayload: { username: email, password } }).then(session => {
      localStorage.setItem("session", JSON.stringify(session));
      history.replace(redirectUrl ?? "/");
    }).catch(ex => {
      console.error("Failed to log in", ex);
      enqueueSnackbar("Login nicht erfolgreich. Bitte überprüfen Sie Ihre Eingaben", { variant: "error" })
    });
  }, [api, email, password, enqueueSnackbar, history, redirectUrl])

  return (
    <Box sx={{
      alignItems: "center",
      backgroundImage: "url(\"/img/background.png\")",
      backgroundSize: "cover",
      display: "flex",
      height: "100vh",
      justifyContent: "center"
    }}>
      <Paper sx={{
        display: "flex",
        boxShadow: "0px 10px 40px #0102024D",
        flexDirection: "column",
        padding: "30px"
      }}>
        <img src="/img/logo.svg" alt="Logo" />
        <Typography variant="h3" sx={{
          color: "#656569",
          fontSize: "20px",
          textAlign: "center",
          fontWeight: "bold",
          textTransform: "uppercase",
          paddingBottom: "32px"
        }}>Gewinnspiel-Manager</Typography>
        <InputField
          label="E-Mail"
          value={email}
          onChange={setEmail}
          textFieldProps={{
            placeholder: "E-Mail"
          }}
        />
        <PasswordField
          label="Passwort"
          value={password}
          onChange={setPassword}
          textFieldProps={{
            placeholder: "Passwort"
          }}
        />
        <Button onClick={login} variant="contained" sx={{
          boxShadow: "0px 5px 10px #C4152899",
          marginTop: "30px"
        }}>Login</Button>
      </Paper>
    </Box>
  )
}
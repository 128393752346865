import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback } from "react";
import { DefaultButton, FormHeadline } from "../../../common/Controls";
import { CustomWinnerField, CustomWinnerFieldPayload, FieldType, OpticianDetailed } from "../../../gen/api";
import { CustomField } from "./CustomField";


interface CustomFieldsProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
}

export const CustomFields = (props: CustomFieldsProps) => {
  const { optician, onOpticianChange } = props;

  const customWinnerFields = optician.customWinnerFields!!;
  const onCustomWinnerFieldsChange = useCallback((customWinnerFields: CustomWinnerField[]) => {
    onOpticianChange({ ...optician, customWinnerFields })
  }, [onOpticianChange, optician]);

  const addField = useCallback(() => {
    const newField: CustomWinnerFieldPayload = {
      name: "Neues Feld",
      fieldType: FieldType.String,
      placeholder: "",
      required: false
    }
    onCustomWinnerFieldsChange([...customWinnerFields, newField as unknown as CustomWinnerField]);
  }, [onCustomWinnerFieldsChange, customWinnerFields]);

  const deleteField = useCallback((field: CustomWinnerField) => {
    onCustomWinnerFieldsChange(customWinnerFields.filter(f => f !== field));
  }, [onCustomWinnerFieldsChange, customWinnerFields]);

  return (
    <Box sx={{
      padding: "16px 30px"
    }}>
      <FormHeadline sx={{
        paddingLeft: 0
      }}>Erforderliche Angaben</FormHeadline>
      <Typography variant="body1" sx={{
        marginBottom: "32px"
      }}>
        Hier können weitere Angaben neben Vorname, Nachname und E-Mail angefordert werden.
      </Typography>
      {customWinnerFields.map((field, i) => (
        <CustomField
          key={field.id}
          index={i}
          customWinnerField={field}
          onCustomWinnerFieldChange={newField => onCustomWinnerFieldsChange(customWinnerFields.map(f => f === field ? newField : f))}
          onDelete={() => deleteField(field)}
        />
      ))}
      <DefaultButton variant="contained" onClick={addField}>Feld hinzufügen</DefaultButton>
    </Box>
  )
}
import { Card } from "@mui/material";
import { Box } from "@mui/system";
import {useCallback, useEffect, useMemo, useState} from "react";
import { CardHeadline } from "../../common/Controls";
import {LegalTexts, OpticianDetailed} from "../../gen/api";
import { CustomFields } from "./customFields/CustomFields";
import { Description } from "./description/Description";
import { Design } from "./design/Design";
import { DiscountDetails } from "./discount/DiscountDetails";
import { Legal } from "./legal/legal";
import { Prices } from "./prices/Prices";
import { RaffleCodes } from "./raffleCodes/RaffleCodes";
import {useHistory, useLocation} from "react-router";
import { Email } from "./email/Email";


interface RaffleTabsProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
  legalTexts: LegalTexts | undefined;
  onLegalTextsChange: (legalTexts: LegalTexts) => void;
}

interface TabProps {
  active: boolean;
  label: string;
  onClick: () => void;
}

const Tab = (props: TabProps) => {
  const { active, label, onClick } =  props;

  return (
    <Box onClick={onClick} sx={{
      backgroundColor: active ? "#000000CC" : "#ffffff00",
      color: active ? "white" : "#C41528",
      cursor: "pointer",
      fontSize: "12px",
      fontWeight: "bold",
      padding: "12px 16px",
      textTransform: "uppercase"
    }}>
      {label}
    </Box>
  )
}

type SelectedTab = "description" | "design" | "prices" | "customFields" | "codes" | "discount" | "legalTexts" | "email";

export const RaffleTabs = (props: RaffleTabsProps) => {

  const { optician, onOpticianChange, legalTexts, onLegalTextsChange } = props;

  const [tab, setTab] = useState<SelectedTab>("description");

  const history = useHistory();
  const { search, pathname } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const tabName = queryParams.get("tab");
    if (tabName) {
      setTab(tabName as SelectedTab);
    }
  }, [queryParams]);

  const updateTab = useCallback((tab: SelectedTab) => {
    history.push({
      pathname,
      search: `?tab=${tab}`
    })
    setTab(tab);
  }, [history, pathname])

  return (
    <Card>
      <CardHeadline variant="h4">Gewinnspiel</CardHeadline>
      <Box sx={{
        display: "flex",
        margin: "20px"
      }}>
        <Tab active={tab === "description"} label="Beschreibung" onClick={() => updateTab("description")} />
        <Tab active={tab === "design"} label="Design" onClick={() => updateTab("design")} />
        <Tab active={tab === "prices"} label="Gewinne" onClick={() => updateTab("prices")} />
        <Tab active={tab === "customFields"} label="Gewinn-Formular" onClick={() => updateTab("customFields")} />
        <Tab active={tab === "codes"} label="Gutscheincodes" onClick={() => updateTab("codes")} />
        <Tab active={tab === "discount"} label="Rabatt-Aktion" onClick={() => updateTab("discount")} />
        <Tab active={tab === "legalTexts"} label="Rechtliche Texte" onClick={() => updateTab("legalTexts")} />
        <Tab active={tab === "email"} label="Email" onClick={() => updateTab("email")} />
      </Box>
      {tab === "description" && <Description optician={optician} onOpticianChange={onOpticianChange} />}
      {tab === "design" && <Design optician={optician} onOpticianChange={onOpticianChange} /> }
      {tab === "prices" && <Prices optician={optician} />}
      {tab === "customFields" && <CustomFields optician={optician} onOpticianChange={onOpticianChange} />}
      {tab === "codes" && <RaffleCodes optician={optician} />}
      {tab === "discount" && <DiscountDetails optician={optician} onOpticianChange={onOpticianChange} />}
      {tab === "legalTexts" && <Legal optician={optician} legalTexts={legalTexts} onLegalTextsChange={onLegalTextsChange} />}
      {tab === "email" && <Email optician={optician} onOpticianChange={onOpticianChange} />}
    </Card>
  )
}
import { DataTablePagination } from "./DataTablePagination";
import { Box } from "@mui/system"


interface DataTableFooterProps {
  page: number;
  rowsTotal: number;
  rowsPerPage: number;
  onPageChange: (page: number) => void;
}

export const DataTableFooter = (props: DataTableFooterProps) => {
  const { page, rowsPerPage, rowsTotal, onPageChange } = props;
  const start = rowsTotal === 0 ? 0 : page * rowsPerPage + 1;
  const end = Math.min((page + 1) * rowsPerPage, rowsTotal);

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "space-between",
      marginTop: "30px"
    }}>
      <Box sx={{
        color: "#666666"
      }}>
        Einträge {start} bis {end} von {rowsTotal}
      </Box>
      <DataTablePagination
        page={page}
        rowsTotal={rowsTotal}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
      />
    </Box>
  )
}
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";


interface TopBarProps {
  title: string;
  subtitle?: string;
}

export const TopBar = (props: PropsWithChildren<TopBarProps>) => {
  return (
    <Box sx={{
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 5px 10px #00000012",
      boxSizing: "border-box",
      color: "#656569",
      display: "flex",
      justifyContent: "space-between",
      height: "100px",
      padding: "30px"
    }}>
      <Box>
        <Typography variant="h1" sx={{
          fontSize: "16px",
          fontWeight: "bold",
          textTransform: "uppercase"
        }}>{props.title}</Typography>
        {props.subtitle && 
          <Typography variant="h2" sx={{
            color: "#656569",
            fontSize: "12px",
            fontWeight: "600",
            marginTop: "4px",
            opacity: 0.48
          }}>{props.subtitle}</Typography>
        }
      </Box>
      <Box sx={{
        alignItems: "center",
        display: "flex"
      }}>
        {props.children}
      </Box>
    </Box>
  )
}
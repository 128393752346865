import { Box, Button, CircularProgress, TextField } from "@mui/material"
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../app/ApiProvider";

interface FileUploadProps {
  onFileChange?: (file: File | null) => void;
  onFileRefChange: (fileRef: string | null) => void;
  onUploadingChange?: (uploading: boolean) => void;
  accept?: string;
}

export const FileUpload = (props: FileUploadProps) => {
  const { onFileChange, onFileRefChange, onUploadingChange, accept } = props;

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    onUploadingChange?.(uploading);
  }, [uploading, onUploadingChange]);

  useEffect(() => {
    if (accept) {
      setFile(null);
      setUploading(false);
    }
  }, [accept, setFile, setUploading]);

  const upload = useCallback(async (file: File | undefined) => {
    if (!file) {
      onFileChange?.(null)
      return;
    }
    try {
      setUploading(true);
      setFile(file);
      const uploadInfo = await api.upload({ contentType: file.type, filename: file.name });
      const response = await fetch(uploadInfo.uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type
        },
        body: file
      });
      if (response.status !== 200) {
        throw new Error("Upload returned error code " + response.status);
      }
      onFileRefChange(uploadInfo.fileRef);
      onFileChange?.(file);
    } catch (ex) {
      setFile(null);
      enqueueSnackbar("Upload fehlgeschlagen", { variant: "error" });
    } finally {
      setUploading(false);
    }
  }, [onFileChange, api, enqueueSnackbar, onFileRefChange]);

  return (
      <Box sx={{
        alignItems: "stretch",
        display: "flex"
      }}>
        <Button
          variant="contained"
          component="label"
        >
          {!uploading && "Datei auswählen"}
          {!uploading && <input
            type="file"
            accept={accept ?? ".*"}
            hidden
            onChange={ev => upload(ev.target.files?.[0])}
          />}
          {uploading && <CircularProgress sx={{
            color: "white"
          }} />}
        </Button>

        <TextField
          disabled
          InputProps={{ sx: { borderRadius: "4px", borderTopRightRadius: "2px", borderBottomRightRadius: "2px", height: "100%" } }}
          value={file?.name ?? ""}
          sx={{
            flexGrow: 1
          }} 
        />
      </Box>
  )
}
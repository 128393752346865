import { Box, Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useApi } from "../../../app/ApiProvider";
import { OpticianDetailed } from "../../../gen/api";

interface CsvUploadProps {
  optician: OpticianDetailed;
  onRaffleCodesUpdate: () => void;
}

export const CsvUpload = (props: CsvUploadProps) => {
  const { optician, onRaffleCodesUpdate } = props;

  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const upload = useCallback((file: File | undefined) => {
    if (!file) {
      return;
    }
    setUploading(true);
    api.updateRaffleCodesByOpticianWithCsv({
      id: optician.id,
      body: file
    }).then(() => {
      enqueueSnackbar("CSV Upload erfolgreich", { variant: "success" });
      setUploading(false);
      onRaffleCodesUpdate();
    }).catch(ex => {
      console.error("Raffle code csv upload failed", ex);
      enqueueSnackbar("CSV Upload fehlgeschlagen", { variant: "error" });
      setUploading(false);
    });
  }, [setUploading, api, optician, enqueueSnackbar, onRaffleCodesUpdate]);

  const download = useCallback(() => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    api.getRaffleCodesByOpticianAsCsv({ id: optician.id }).then(csv => {
      setDownloading(false);
      const blob = new Blob([csv.text], {
        type: "text/csv"
      });
      const aElem = document.createElement('a');
      aElem.href = URL.createObjectURL(blob);
      aElem.download = `${optician.name}-Gewinner.csv`;
      document.body.appendChild(aElem);
      aElem.click();
      document.body.removeChild(aElem);
    }).catch(ex => {
      setDownloading(false);
      console.log("Failed to download winner csv", ex);
      enqueueSnackbar("CSV download fehlgeschlagen", { variant: "error" });
    })
  }, [api, optician, enqueueSnackbar, downloading, setDownloading]);

  return (
    <Box sx={{
      backgroundColor: "#F7F7F7",
      border: "1px solid #E7EBEC"
    }}>
      <Button variant="contained" component="label" sx={{
        boxShadow: "0px 5px 10px #C4152899",
        margin: "30px 24px"
      }}>
      {!uploading && <input
          type="file"
          accept=".csv"
          hidden
          onChange={ev => upload(ev.target.files?.[0])}
        />}
        {uploading && <CircularProgress sx={{
          color: "white"
        }} />}
        {!uploading && <img src="/img/ic_upload.svg" alt="" />}
        {!uploading && <Box sx={{ marginLeft: "12px" }}>CSV-Datei hochladen</Box>}
      </Button>
      <Button variant="contained" component="label" onClick={download} sx={{
        boxShadow: "0px 5px 10px #C4152899",
        margin: "30px 24px"
      }}>
        {downloading && <CircularProgress sx={{
          color: "white"
        }} />}
        {!downloading && <img src="/img/ic_download.svg" alt="" />}
        {!downloading &&
          <Box sx={{ marginLeft: "12px" }}>
            Download Gewinner
          </Box>
        }
      </Button>
    </Box>
  )
}
/* tslint:disable */
/* eslint-disable */
/**
 * IGA Gewinnspiel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: info@wapp.gmbh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpticianPayload
 */
export interface OpticianPayload {
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof OpticianPayload
     */
    sendWinMailCustomer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OpticianPayload
     */
    sendWinMailOptician: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    streetAndNumber: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    winnerMailTemplate: string;
    /**
     * 
     * @type {string}
     * @memberof OpticianPayload
     */
    zip: string;
}

export function OpticianPayloadFromJSON(json: any): OpticianPayload {
    return OpticianPayloadFromJSONTyped(json, false);
}

export function OpticianPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpticianPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'email': json['email'],
        'name': json['name'],
        'sendWinMailCustomer': json['sendWinMailCustomer'],
        'sendWinMailOptician': json['sendWinMailOptician'],
        'slug': json['slug'],
        'streetAndNumber': json['streetAndNumber'],
        'winnerMailTemplate': json['winnerMailTemplate'],
        'zip': json['zip'],
    };
}

export function OpticianPayloadToJSON(value?: OpticianPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'email': value.email,
        'name': value.name,
        'sendWinMailCustomer': value.sendWinMailCustomer,
        'sendWinMailOptician': value.sendWinMailOptician,
        'slug': value.slug,
        'streetAndNumber': value.streetAndNumber,
        'winnerMailTemplate': value.winnerMailTemplate,
        'zip': value.zip,
    };
}



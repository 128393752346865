import { Box, styled } from "@mui/system";
import { OpticianDetailed, Raffle } from "../../../gen/api";
import { Form, FormHeadline, FormInput, FormLabel } from "../../../common/Controls";
import { useCallback } from "react";
import 'react-quill/dist/quill.snow.css';
import { PriceImage } from "./PriceImage";
import {Quill} from "../../../common/Quill";

const StyledQuill = styled(Quill)(() => ({
  marginTop: "28px",
  "& .ql-editor": {
    minHeight: "150px"
  }
}))

interface DescriptionProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
}

export const Description = (props: DescriptionProps) => {
  const { optician, onOpticianChange } = props;

  const raffle = optician.raffle!!;
  const onRaffleChange = useCallback((raffle: Raffle) => {
    onOpticianChange({ ...optician, raffle });
  }, [optician, onOpticianChange]);

  return <Box>
    <FormHeadline>Text</FormHeadline>
    <Form>
      <FormInput
        label="Name des Gewinnspiels"
        value={raffle.name!!}
        onChange={name => onRaffleChange({ ...raffle, name })}
      />
      <FormLabel>Begrüßungstext</FormLabel>
      <StyledQuill
        value={raffle.greeting}
        onChange={greeting => onRaffleChange({ ...raffle, greeting })}
      />
      <FormLabel>Gewinn-Nachricht</FormLabel>
      <StyledQuill
        value={raffle.preparePriceMessage}
        onChange={preparePriceMessage => onRaffleChange({ ...raffle, preparePriceMessage })}
      />
    </Form>
    <PriceImage 
      raffle={raffle}
      onRaffleChange={onRaffleChange}
    />
  </Box>
}
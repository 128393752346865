import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useApi } from "../app/ApiProvider"
import { SquareButton } from "../common/Controls";
import { DataTable } from "../common/data_table/DataTable";
import { Optician } from "../gen/api";
import { PortalContent } from "../portal/PortalContent";
import { TopBar } from "../portal/TopBar";


export const OpticianTable = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const api = useApi();

  const [deleteDialogOptician, setDeleteDialogOptician] = useState<Optician>();
  const [dataTableCacheKey, setDataTableCacheKey] = useState(new Date());

  const deleteOptician = useCallback(() => {
    setDeleteDialogOptician(undefined);
    api.deleteOptician({ id: deleteDialogOptician!!.id }).then(() => {
      enqueueSnackbar("Optiker gelöscht", { variant: "success" })
      setDataTableCacheKey(new Date());
    }).catch(ex => {
      console.error("Could not delete optician", ex);
      enqueueSnackbar("Optiker konnte nicht gelöscht werden", { variant: "error" });
      setDataTableCacheKey(new Date());
    });
  }, [api, deleteDialogOptician, enqueueSnackbar]);

  const addOptician = useCallback(() => {
    api.createOptician({
      opticianPayload: {
        name: "Neuer Optiker",
        slug: "neuer-optiker",
        email: "",
        city: "",
        streetAndNumber: "",
        zip: "",
        sendWinMailCustomer: true,
        sendWinMailOptician: true,
        winnerMailTemplate: ""
      }
    }).then(optician => {
      history.push(`/optician/${optician.id}`);
    }).catch(ex => {
      console.log("Could not create optician", ex);
      enqueueSnackbar("Optiker konnte nicht erstellt werden", { variant: "error" });
    })
  }, [api, enqueueSnackbar, history]);
  

  return (
    <>
      <TopBar title="Optiker">
        <SquareButton variant="contained" onClick={addOptician}>
          <img src="/img/ic_add.svg" alt="Optiker hinzufügen" />
        </SquareButton>
      </TopBar>
      <PortalContent component="main">
        <DataTable<Optician>
          cacheKey={dataTableCacheKey}
          dataSupplier={api.getOpticians.bind(api)}
          columns={[
            {
              name: "name",
              label: "Name"
            },
            {
              name: "streetAndNumber",
              label: "Anschrift"
            }, {
              name: "zip",
              label: "PLZ"
            }, {
              name: "city",
              label: "Stadt"
            },
            {
              label: "",
              sortable: false,
              render: row => (
                <Box sx={{
                  display: "flex",
                  justifyContent: "end"
                }}>
                  <IconButton component={Link} to={`/optician/${row.id}`}>
                    <img src="/img/table_edit.svg" alt="Bearbeiten" />
                  </IconButton>
                  <IconButton onClick={() => setDeleteDialogOptician(row)}>
                    <img src="/img/table_delete.svg" alt="Löschen" />
                  </IconButton>
                </Box>
              )
            }
          ]}
        />
        <Dialog open={deleteDialogOptician !== undefined} onClose={() => setDeleteDialogOptician(undefined)}>
          <DialogTitle>Optiker "{deleteDialogOptician?.name}" löschen?</DialogTitle>
          <DialogContent>
            Der Optiker und das dazugehörige Gewinnspiel werden gelöscht.
            Diese Aktion kann nicht rückgängig gemacht werden.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOptician(undefined)}>Abbrechen</Button>
            <Button onClick={deleteOptician}>Optiker löschen</Button>
          </DialogActions>
        </Dialog>
      </PortalContent>
    </>
  )
}
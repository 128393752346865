import { Box } from "@mui/system";
import { Route, Switch } from "react-router";
import { OpticianDetails } from "../opticians/OpticianDetails";
import { OpticianTable } from "../opticians/OpticianTable";
import { SideBar } from "./SideBar";


export const Portal = () => {
  return (
    <Box sx={{
      display: "flex",
      height: "100vh"
    }}>
      <SideBar />
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%"
      }}>
        <Switch>
          <Route path="/" exact={true}>
            <OpticianTable />
          </Route>
          <Route path="/optician/:opticianId">
            <OpticianDetails />
          </Route>
        </Switch>
      </Box>
    </Box>
  )
}
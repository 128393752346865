import {OpticianDetailed} from "../../../gen/api";
import {Box, Switch} from "@mui/material";
import {Form, FormHeadline, FormLabel} from "../../../common/Controls";
import {Quill} from "../../../common/Quill";

interface EmailProps {
  optician: OpticianDetailed
  onOpticianChange: (optician: OpticianDetailed) => void;
}

export const Email = (props: EmailProps) => {
  const {optician, onOpticianChange} = props

  return (
    <Box>
      <FormHeadline>Email</FormHeadline>
      <Form>
        <Box sx={{gridColumn: "1/4", display: "flex"}}>
          <FormLabel>Bei Gewinn Mail an den Optiker schicken</FormLabel>
          <Switch sx={{marginTop: "6px"}} checked={optician.sendWinMailOptician}
                  onChange={ev => onOpticianChange({...optician, sendWinMailOptician: ev.target.checked})}/>
        </Box>
        <Box sx={{gridColumn: "1/4", display: "flex", marginBottom: "32px"}}>
          <FormLabel>Bei Gewinn Mail an den Kunden schicken</FormLabel>
          <Switch sx={{marginTop: "6px"}} checked={optician.sendWinMailCustomer}
                  onChange={ev => onOpticianChange({...optician, sendWinMailCustomer: ev.target.checked})}/>
        </Box>
        {optician.sendWinMailCustomer &&
            <>
                <Box sx={{
                  gridColumn: "1/4",
                  marginBottom: "32px"
                }}>
                    Hier können Sie die Mail, die beim Gewinn an den Kunden geschickt wird, konfigurieren. Außerdem können Sie
                    Platzhalter verwenden, die beim Verschicken der E-Mail mit den passenden Werten
                    ausgetauscht werden.
                </Box>
                <Box sx={{
                  gridColumn: "1/4",
                  marginBottom: "32px"
                }}>
                    Folgende Platzhalter können Sie
                    verwenden: <br/> &#123;kunde-vorname&#125;, &#123;kunde-nachname&#125;, &#123;preis&#125;, &#123;optiker-adresse&#125;, &#123;optiker-name&#125;
                </Box>
                <Box sx={{
                  gridColumn: "1/3"
                }}>
                    <Quill
                        value={optician.winnerMailTemplate}
                        onChange={winnerMailTemplate => onOpticianChange({...optician, winnerMailTemplate})}
                    />
                </Box>
            </>
        }
      </Form>
    </Box>
  )
}
import { Box } from "@mui/material"
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router"
import { NavBarButton, NavButton } from "./NavButton"

export const SideBar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const legalActive = location.pathname.startsWith("/legal");

  const logout = useCallback(() => {
    localStorage.removeItem("session");
    enqueueSnackbar("Logout erfolgreich", { variant: "success" });
    history.push("/login");
  }, [enqueueSnackbar, history]);

  return (
    <Box component="nav" sx={{
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      minHeight: "100vh",
      width: "280px"
    }}>
      <Box sx={{
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        display: "flex",
        height: "100px",
        justifyContent: "center"
      }}><img src="/img/logo.svg" alt="Logo" /></Box>
      <Box sx={{
        background: "linear-gradient(344deg, #353539 0%, #656569 100%)",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        justifyContent: "space-between",
        paddingTop: "40px"
      }}>
        <Box>
          <NavButton to="/" active={!legalActive}>
            <img src="/img/ic_nav_optician.svg" alt="" />
            Optiker
          </NavButton>
        </Box>
        <NavBarButton onClick={logout} sx={{
          cursor: "pointer"
        }}>
          <img src="/img/ic_nav_logout.svg" alt="" />
          Logout
        </NavBarButton>
      </Box>
    </Box>
  )
}
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { SetStateAction, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useApi } from "../../app/ApiProvider";
import { SquareButton } from "../../common/Controls";
import {
  CustomWinnerFieldPayload,
  DiscountPayload,
  LegalTexts, LegalTextsPayload,
  OpticianDesignPayload,
  OpticianDetailed,
  OpticianPayload,
  RafflePayload
} from "../../gen/api";
import { TopBar } from "../../portal/TopBar";
import { RaffleLink } from "./RaffleLink";


interface OpticianTopBarProps {
  optician: OpticianDetailed;
  onOpticianChange: React.Dispatch<SetStateAction<OpticianDetailed>>;
  legalTexts: LegalTexts | undefined;
}

export const OpticianTopBar = (props: OpticianTopBarProps) => {
  const { optician, onOpticianChange, legalTexts } = props;
  const api = useApi();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const deleteOptician = useCallback(() => {
    api.deleteOptician({ id: optician.id }).then(() => {
      history.push("/");
    }).catch((ex: Response) => {
      console.error("Could not delete optician", ex);
      enqueueSnackbar("Optiker konnte nicht gelöscht werden", { variant: "error" });
    });
  }, [api, optician.id, enqueueSnackbar, history]);

  const saveOptician = useCallback(() => {
    const updateOptician = api.updateOptician({ id: optician.id, opticianPayload: optician as OpticianPayload }).then(updated => {
        onOpticianChange(o => ({ ...o, ...updated }));
        return null;
      }).catch((ex: Response) => {
        console.error("Failed to save optician", ex);
        return ex;
      });

    const updateRaffle = api.updateRaffleByOptician({ id: optician.id, rafflePayload: optician.raffle as RafflePayload }).then(raffle => {
        onOpticianChange(o => ({ ...o, raffle }));
        return null;
      }).catch((ex: Response) => {
        console.error("Failed to save raffle", ex);
        return ex;
      });

    const updateDesign = api.updateOpticianDesignByOptician({ id: optician.id, opticianDesignPayload: optician.design as OpticianDesignPayload }).then(design => {
        onOpticianChange(o => ({ ...o, design }));
        return null;
      }).catch((ex: Response) => {
        console.error("Failed to save design", ex);
        return ex;
      });

    const updateCustomWinnerFields = api.updateCustomWinnerFieldsByOptician({ id: optician.id, customWinnerFieldPayload: optician.customWinnerFields!! as CustomWinnerFieldPayload[] }).then(customWinnerFields => {
      onOpticianChange( o => ({ ...o, customWinnerFields }));
      return null;
    }).catch((ex: Response) => {
      console.error("Failed to save custom winner fields", ex);
      return ex;
    });

    const updateDiscount = api.updateDiscountByOptician({ id: optician.id, discountPayload: optician.discount as DiscountPayload }).then(discount => {
      onOpticianChange(o => ({ ...o, discount }))
      return null;
    }).catch((ex: Response) => {
      console.error("Failed to save discount", ex);
      return ex;
    });

    const saveAll: Promise<unknown>[] = [updateOptician, updateRaffle, updateDesign, updateCustomWinnerFields, updateDiscount];
    if (legalTexts) {
      const updateLegalTexts = api.updateLegalTextsByOptician({ id: optician.id, legalTextsPayload: legalTexts as LegalTextsPayload }).then(() => {
        return null;
      }).catch((ex: Response) => {
        console.error("Failed to save participation", ex);
        return ex;
      });
      saveAll.push(updateLegalTexts);
    }
    return Promise.all(saveAll).then(async (errors: unknown[]) => {
      const ex = errors as (Response | null)[];
      if (!ex.find(e => e)) {
        enqueueSnackbar("Optiker wurde erfolgreich gespeichert", { variant: "success" });
      } else {
        const cause = ex.find(ex => ex?.status === 409)
        if (cause) {
          const body = await cause.json();
          if (body.message.startsWith("NAME_EXISTS:")) {
            enqueueSnackbar("Es existiert bereits ein anderer Optiker mit diesem Namen", { variant: "error" });
            return ex;
          } else if (body.message.startsWith("SLUG_EXISTS:")) {
            enqueueSnackbar("Es existiert bereits ein anderer Optiker mit diesem Gewinnspiel-Pfad", { variant: "error" });
            return ex;
          }
        }
        enqueueSnackbar("Optiker konnte nicht gespeichert werden", { variant: "error" });
      }
      return ex;
    });
  }, [api, optician, onOpticianChange, legalTexts, enqueueSnackbar]);

  const saveAndCloseOptician = useCallback(() => {
    saveOptician().then(ex => {
      if (!ex.find(e => e)) {
        history.push("/");
      }
    })
  }, [saveOptician, history]);

  return (
    <TopBar title={optician.name!!} subtitle={optician.id}>
      <IconButton onClick={() => setDeleteDialogOpen(true)}>
        <img src="/img/ic_delete.svg" alt="Optiker löschen" />
      </IconButton>
      <RaffleLink optician={optician} />
      <SquareButton onClick={() => { saveOptician() }} sx={{
        borderRadius: "2px",
        boxShadow: "none",
        margin: "5px"
      }}>
        <img src="/img/ic_save.svg" alt="Optiker speichern" />
      </SquareButton>
      <SquareButton onClick={() => { saveAndCloseOptician() }} sx={{
        backgroundColor: "#C41528",
        borderRadius: "2px",
        boxShadow: "none",
        margin: "5px"
      }}>
        <img src="/img/ic_publicate_white.svg" alt="Optiker speichern" />
      </SquareButton>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Optiker "{optician.name}" löschen?</DialogTitle>
        <DialogContent>
          Der Optiker und das dazugehörige Gewinnspiel werden gelöscht.
          Diese Aktion kann nicht rückgängig gemacht werden.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Abbrechen</Button>
          <Button onClick={deleteOptician}>Optiker löschen</Button>
        </DialogActions>
      </Dialog>
    </TopBar>
  )
}
import { Box } from "@mui/material";
import { useCallback } from "react";
import { Form, FormHeadline } from "../../../common/Controls";
import { ImageUpload } from "../../../common/ImageUpload";
import { OpticianDesign, OpticianDetailed } from "../../../gen/api";
import { Background } from "./Background";
import { Colors } from "./Colors";
import { Fonts } from "./Fonts";
import {Waiting} from "./Waiting";

interface DesignProps {
  optician: OpticianDetailed;
  onOpticianChange: (optician: OpticianDetailed) => void;
}

export const Design = (props: DesignProps) => {
  const { optician, onOpticianChange } = props;

  const design = optician.design!!;
  const onDesignChange = useCallback((design: OpticianDesign) => {
    onOpticianChange({ ...optician, design })
  }, [onOpticianChange, optician]);

  return (
    <Box>
      <FormHeadline>Logo</FormHeadline>
      <Form>
        <ImageUpload
          label="Logo auswählen"
          payload={design}
          onPayloadChange={onDesignChange}
          urlKey="logoUrl"
        />
      </Form>
      <Fonts design={design} onDesignChange={onDesignChange} />
      <Colors design={design} onDesignChange={onDesignChange} />
      <Background design={design} onDesignChange={onDesignChange} />
      <Waiting design={design} onDesignChange={onDesignChange} />
    </Box>
  )
}

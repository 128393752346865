import { CircularProgress } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useAsync } from "react-async-hook";
import { useParams } from "react-router-dom";
import { useApi } from "../app/ApiProvider"
import {LegalTexts, OpticianDetailed} from "../gen/api";
import { PortalContent } from "../portal/PortalContent";
import { MasterData } from "./MasterData";
import { OpticianTopBar } from "./topBar/OpticianTopBar";
import { RaffleTabs } from "./raffle/RaffleTabs";


export const OpticianDetails = () => {
  const api = useApi();
  const { opticianId } = useParams<{ opticianId: string }>();

  const [optician, setOptician] = useState<OpticianDetailed | null>();
  const [legalTexts, setLegalTexts] = useState<LegalTexts>();
  const asyncOptician = useAsync((opticianId: string) => api.getOptician({ id: opticianId }), [opticianId]);

  useEffect(() => {
    if (asyncOptician.result) {
      setOptician(asyncOptician.result);
    }
  }, [asyncOptician.result, setOptician]);

  if (asyncOptician.error) {
    return <div>Optiker konnte nicht geladen werden</div>
  }

  if (!optician) {
    return <CircularProgress />
  }

  return (
    <>
      <OpticianTopBar optician={optician} onOpticianChange={setOptician as React.Dispatch<SetStateAction<OpticianDetailed>>} legalTexts={legalTexts} />
      <PortalContent component="main">
        <MasterData optician={optician} onOpticianChange={setOptician} />
        <RaffleTabs optician={optician} onOpticianChange={setOptician} legalTexts={legalTexts} onLegalTextsChange={setLegalTexts} />
      </PortalContent>
    </>
  )
}